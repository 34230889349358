// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, getDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKzmGtz_c1c7Pd5lHX-AUm8UQFe7QueXA",
  authDomain: "thecarlyleapartments-ai.firebaseapp.com",
  databaseURL: "https://thecarlyleapartments-ai-default-rtdb.firebaseio.com",
  projectId: "thecarlyleapartments-ai",
  storageBucket: "thecarlyleapartments-ai.appspot.com",
  messagingSenderId: "194967899488",
  appId: "1:194967899488:web:ec89035cba9cebd4286205",
  measurementId: "G-EBB97C4Q38"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

document.addEventListener('DOMContentLoaded', async function(e) {
    let docRef = doc(db, 'recent-report', 'report');
    let docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data());
        const { category, date, desc } = docSnap.data();
        document.querySelector('#recent-category').textContent = category;
        document.querySelector('#recent-date').textContent = date;
        document.querySelector('#recent-desc').textContent = desc;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

    docRef = doc(db, 'counters', 'criminal_activity_report');
    docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data());
        const { report_count, reviewed_reports_count, other_count, theft_count, vandalism_count, burglaries_count, suspicious_person } = docSnap.data();
        document.querySelector('#total-reports').textContent = report_count;
        document.querySelector('#reviewed').textContent = reviewed_reports_count;
        document.querySelector('#other').textContent = other_count;
        document.querySelector('#theft').textContent = theft_count;
        document.querySelector('#vandalism').textContent = vandalism_count;
        document.querySelector('#burglaries').textContent = burglaries_count;
        document.querySelector('#suspicious').textContent = suspicious_person;

      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    
    const $notifDel = document.querySelector('.notification .delete');
    const $notification = $notifDel.parentNode;
    $notifDel.addEventListener('click', () => {
        $notification.parentNode.removeChild($notification);
        const $content = document.querySelector('#content').setAttribute("padding-top", "48px");
      });

    const emailEl = document.querySelector('[name="email"]');
    // emailEl.addEventListener('invalid', function() {
    //     emailEl.classList.add('is-danger');
    //     const emailValidation = () => {
    //         if((/[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{0}/).test(emailEl.value)) {
    //             emailEl.classList.remove('is-danger');
    //             //emailEl.removeEventListener('keypress', emailValidation);
    //         }
    //     }
    //     emailEl.addEventListener('keypress', emailValidation);
    // });

    const categoryEl = document.querySelector('[name="category"]');
    const describeCategory = document.querySelector('#describe-category');
    categoryEl.addEventListener('invalid', function(e) {
        categoryEl.classList.add('is-danger-selector');
    });
    categoryEl.addEventListener('change', function(e) {
        
        if('' == e.target.value) {
            categoryEl.classList.add('is-danger-selector');
        } else if('other' == e.target.value) {
            describeCategory.classList.remove('is-hidden');
            describeCategory.querySelector('input').setAttribute('required', '');
            categoryEl.classList.remove('is-danger-selector');
        } else {
            describeCategory.classList.add('is-hidden');
            describeCategory.querySelector('input').removeAttribute('required');
            categoryEl.classList.remove('is-danger-selector');
        }
    });

    document.querySelector('form').addEventListener('submit', async function(e) {
        e.preventDefault();
        //console.log(this);
        const formData = new FormData(document.querySelector('form'));


        const data = {};

        for(const [key, value] of formData) {
            data[key] = value;
        }

        await addDoc(collection(db, 'reports'), data);

        const html = `
            <div class="notification is-success is-light">
                <h1 class="title">Submission Successful</h1>
                <h2 class="subtitle">Thank you for helping keep our community safe.</h2>
                While we review your report, please take a moment to consider signing
                the petition <a target="_blank" href="https://www.change.org/the-carlyle-apartments">Save our Community, 
                the Carlyle Management Team must improve Security</a>. If you would like to be more vocal,
                we encourage all residents to submit an honest review of the Carlyle on <a target="_blank" href="https://www.yelp.com/biz/the-carlyle-santa-clara">Yelp</a>.
            </div>
        `;

        const placeholder = document.createElement('div');
        placeholder.insertAdjacentHTML('afterbegin', html);
        const node = placeholder.firstElementChild; 
        this.parentNode.replaceChild(node, this);

    });
});

